
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Authentication, AuthServiceType } from '@movecloser/front-core'

import { Inject } from '../../support'
import { PaginationProps } from '../../dsl/molecules/Pagination'
import { ProductCardVariant } from '../../front/products/organisms/ProductCard/ProductCard.contracts'
import { ProductReviewData, Variant } from '../../contexts'
import { translateProductToProductCard } from '../../front/products/organisms/ProductCard/ProductCard.helpers'
import { UserModel } from '../../front/auth/shared'

import { getPaginationConfig } from './ProductReviews.helpers'
import { ProductReviewsConfig, ProductReviewsID } from './ProductReviews.config'
import { ProductReviewsModuleData } from './ProductReviews.contracts'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<ProductReviewsMixin>({
  name: 'ProductReviewsMixin',
  created (): void {
    if (this.content.product && !this.config.useVendorReviews) {
      this.setActiveVariant(this.content.product.variants[0].sku)
    }
  }
})
export class ProductReviewsMixin extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly config!: ProductReviewsConfig

  @Prop({ type: Object, required: true })
  protected readonly content!: ProductReviewsModuleData

  @Inject(AuthServiceType, false)
  private readonly authService?: Authentication<UserModel>

  /**
   * Determines variant which is currently displayed.
   */
  public activeVariant: ProductCardVariant | undefined | null = null
  /**
   * Articles list current page.
   */
  public currentPage: number = 1
  /**
   * Are reviews being fetched
   */
  public isLoading: boolean = false
  /**
   * Array with reviews objects
   */
  public reviews: ProductReviewData[] | null = null

  /**
   * Determines is list empty.
   */
  public get isEmptyList (): boolean {
    return this.totalReviews === 0
  }

  /**
   * Articles list pagination config.
   */
  public get paginationConfig (): Omit<PaginationProps, 'currentPage'> {
    return getPaginationConfig(this.totalReviews, this.reviewsPerPage)
  }

  /**
   * Determines product related with reviews
   */
  public get product () {
    if (!this.content.product) {
      return
    }

    return this.content.product
  }

  public get productReviewsIdentifier () {
    return ProductReviewsID
  }

  /**
   * Determines amount reviews per page
   */
  public get reviewsPerPage () {
    if (!this.content.reviewsPerPage) {
      return 10
    }

    return this.content.reviewsPerPage
  }

  /**
   * Determines is pagination showed.
   */
  public get showPagination (): boolean {
    return this.content.showPagination
  }

  /**
   * Determines amount of reviews
   */
  public get totalReviews (): number {
    if (!this.reviews || !this.reviews.length) {
      return Number('0')
    }
    return this.reviews.length
  }

  /**
   * Determines product variants
   */
  public get variants (): Variant<string>[] {
    if (!this.product?.variants) {
      return []
    }

    return Object.values(this.product.variants)
  }

  public authCheck (): boolean {
    return this.authService?.check() || false
  }

  /**
   * Sets the active variant.
   * @param sku - sku of the product.
   */
  public setActiveVariant (sku: string): void {
    if (this.product) {
      this.activeVariant = translateProductToProductCard(this.product).variants
        .find((product) => product.sku === sku)
    }
  }
}
